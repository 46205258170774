<template>
  <v-app-bar
    v-scroll="fadeNav"
    :color="offsetTop <= 50 ? 'transparent' : 'rgba(0,0,0,0.5)'"
    fixed
    flat
    height="100"
    style="width: auto"
    dark
  >
    <v-toolbar-title>
      <router-link to="/">
        <v-img :src="require(`@/assets/logo/odro-logo-white.svg`)" max-width="55" class="mr-3" />
      </router-link>
    </v-toolbar-title>
    <v-spacer />
    <template v-for="(item, i) in enrichedPublicNav">
      <v-divider v-if="item.divider" :key="i" vertical class="ml-4 mr-3" />
      <v-btn v-else :key="i" :to="item.to" :href="item.href" :target="item.href ? '_blank' : ''" outlined text>
        <fa-icon :icon="item.icon" style="max-width: 14px" class="mr-2" />
        <span v-text="item.title" />
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PagesCoreAppBar',
  data() {
    return {
      offsetTop: 0,
    };
  },
  computed: {
    ...mapGetters('application', ['publicNav']),
    enrichedPublicNav() {
      return this.publicNav.map(this.mapNavItem);
    },
  },
  methods: {
    fadeNav() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
  },
};
</script>
